@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap')

:root
  --color-primary: #5cadd6
  --color-secondary: #4dcb97
  --color-warning: #ffecb3
  --color-danger: #e76868
  --color-dark: #2c313a

html
  overflow-y: scroll
  scroll-behavior: smooth

body
  padding: 80px 52px
  font-family: 'Lato', sans-serif

.p-component
  font-family: 'Lato'

nav
  position: fixed
  top: 0
  left: 0
  right: 0
  display: flex
  flex-direction: row
  align-items: center
  padding: 8px
  gap: 8px
  background-color: var(--color-dark)
  color: var(--color-secondary)
  z-index: 1000
  user-select: none

footer
  position: fixed
  bottom: 0
  left: 0
  right: 0
  display: flex
  flex-direction: row
  align-items: center
  padding: 8px
  gap: 8px
  background-color: var(--color-dark)
  color: var(--color-secondary)
  z-index: 1000
  user-select: none


h1
  font-size: 22px
  font-weight: 300
  margin: 0
  margin-bottom: 30px
  padding: 0


main
  &.upload-page
    max-width: 1200px
    margin: 0 auto

  &.login-page
    display: flex
    flex-direction: row
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    align-items: center
    justify-content: center

    .login-form
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      text-align: center
      padding: 20px
      border: 1px solid #ccc
      border-radius: 5px
      gap: 8px

      *
        width: 100%

.progress-dialog-form
  display: flex
  flex-direction: column



.publishing-batch
  background-color: #efefef
  border-radius: 5px
  margin-bottom: 30px
  padding: 10px
  display: flex
  flex-direction: column
  min-width: 935px

  &.studio-processing
    background-color: #efdfff

  .toolbar
    display: flex
    flex-direction: row
    align-items: center
    gap: 8px

  .task-list
    display: flex
    flex-direction: column
    align-items: center
    margin: 10px 0
    padding-top: 10px

.big-button-wrapper
  flex-basis: 1200px
  max-width: 1200px
  button
    width: 100%

.small-button-wrapper
  min-width: 35px
  max-height:355px

.publishing-batch-task
  width: 100%
  display: flex
  flex-direction: row
  align-items: center
  border-radius: 5px
  background-color: #ffffff
  margin-bottom: 10px
  padding: 10px
  gap: 8px


.report-list
  display: flex
  flex-direction: column
  gap: 8px
  overflow: auto

  .report-item
    display: flex
    flex-direction: row
    align-items: center
    border: 1px solid #ccc
    border-radius: 5px
    padding: 10px
    gap: 8px


.file-select-widget
  input[type="file"]
    display: none

  label
    border: 1px solid #ccc
    display: inline-block
    padding: 6px 12px
    cursor: pointer


.p-progressbar-determinate .p-progressbar-value-animate
    transition: width 0s ease-in-out


.p-tree
  width: 100%
  height: 100%
  border: 0
  padding: 0

  .p-tree-header
    position: sticky
    top: 0px
    background-color: white !important
    z-index: 900
    width: 100%
    padding-right: 12px

.p-button
  color: var(--color-dark) !important
  &.p-button-info
    background-color: var(--color-primary) !important
    border-color: var(--color-primary) !important

  &.p-button-success:not(.p-button-text)
    background-color: var(--color-secondary) !important
    border-color: var(--color-secondary) !important

  &.p-button-warning:not(.p-button-text)
    background-color: var(--color-warning) !important
    border-color: var(--color-warning) !important

  &.p-button-danger:not(.p-button-text)
    background-color: var(--color-danger) !important
    border-color: var(--color-danger) !important

  &:hover
    text-shadow: 0 0 3px rgba(255,255,255, .5)!important
